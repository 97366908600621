'use strict';

angular.module('ufcw')
  .controller('MyProfileController', function ($log,Request,$localstorage,$rootScope,$uibModal,$document,toaster,$translate,$state) {
    var vm = this;
    vm.errorMsg = null;

    vm.user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.dependents.length === 0)?false:true;
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.my_profile");

    vm.update = function(user){
      Request.put('user/?id='+user.id,true,user,function(response) {
        if(response.status !== 0){
          vm.user  = $localstorage.getObject('user');
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          vm.changeLang(user.lang);
          $localstorage.setObject('user',response.description);
          toaster.pop('success', "", $translate.instant("profile.saved"));
        }
      });
    };

    var LANG_MAP = {
      es: $translate.instant('lang.es'),
      en: $translate.instant('lang.en'),
      null: '',
      '': '',
      undefined: '',
    };

    vm.user.lang_text = LANG_MAP[vm.user.lang];

    // MODAL
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'profile.html',
        controller: 'ModalProfileController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          user: function () {
            return vm.user;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
              vm.update(result.user);
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.edit = function(){
      vm.open();
    };

    vm.changeLang = function(lang){
      $translate.use(lang);
      $state.reload();
    };

  });

  angular.module('ufcw').controller('ModalProfileController', function ($uibModalInstance, $log, user, toaster, $translate) {
    var vm = this;
    
    vm.user = user;
    
    vm.ok = function () {
      try {
        if(vm.user.passwordOld && !vm.user.passwordNew){
          throw $translate.instant("profile.new_pwd_invalid");
        }
        if(vm.user.passwordNew && !vm.user.passwordOld){
          throw $translate.instant("profile.current_pwd_invalid");
        }
        if(!vm.user.email){
          throw $translate.instant("signin.email_invalid");
        }
        if(!vm.user.phone){
          throw $translate.instant("profile.phone_invalid");
        }
        $uibModalInstance.close({user: vm.user, status: 'OK'});
      } catch (error) {
        toaster.pop('error', "", error);
      }
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.langs = [{"id":"es","value":$translate.instant('lang.es')},{"id":"en","value":$translate.instant('lang.en')}];
    
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'profile.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.user = vm.resolve.user;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
