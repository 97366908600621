'use strict';

angular.module("ufcw")

.directive('formatDate', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.on('keydown', function(e){
        var k = e.keyCode;

        if(
          k != 8 &&
          k != 9 &&
          k != 13 &&
          (( k < 48 || k > 105 ) || (k > 57 && k < 96))
        ){
          e.preventDefault();
        }
      });
      element.on('keyup', function(e){
        var v = element.val();
        var k = e.keyCode;
        if(k == 8 && (v.length == 3 || v.length == 6)){
          scope.$apply(function () {
            element.val( v.slice(0,-1) );
          });
        }
        else if(v.length == 2 || v.length == 5){
          scope.$apply(function () {
            element.val( v + "/");
          });
        }
      });
    }
  }
});
