'use strict';

angular.module("ufcw")

.filter('escapeRegex', function(){
    return function(str){
      return (str?str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"):"");
    }
});
  
