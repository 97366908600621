angular.module("ufcw")

.factory('Request', function($http,$localstorage,EnvConfig,$log,$state,toaster,$translate){
        return {
          get: function(params,need_token,callback){
            var config = {
              method: "GET",
              url: EnvConfig.URL_API + params,
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          post: function(params,need_token,data,callback){
            var config = {
              method: "POST",
              url: EnvConfig.URL_API + params,
              data: JSON.stringify(data),
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          put: function(params,need_token,data,callback){
            var config = {
              method: "PUT",
              url: EnvConfig.URL_API + params,
              data: JSON.stringify(data),
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          },
          delete: function(params,need_token,callback){
            var config = {
              method: "DELETE",
              url: EnvConfig.URL_API + params,
              headers: {"Content-Type": "application/json"}
            };
            if(need_token){
              var token = $localstorage.get('token');
              config.headers["Authorization"] = "Bearer "+token+"";
            }
            $http(config).then(
              function success(response) {
                callback(response.data);
              },
              function error(err){
                if(err.data.status === "401"){
                  toaster.pop('error', "", $translate.instant('rest.session_expired'));
                  callback($translate.instant('rest.session_expired'));
                  $localstorage.clear();
                  $state.go('signin');
                }else{
                  callback(err.data);
                }
              }
            );
          }
        };
      });
