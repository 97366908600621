'use strict';

angular.module('ufcw')
  .controller('NoticeController', function ($log,Request,$localstorage,$rootScope,$uibModal,$document,toaster,$translate,$sce) {
    var vm = this;
    
    vm.user  = $localstorage.getObject('user');
    vm.dependents = $localstorage.getObject('dependents');
    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant('notice.title');
    var text = $translate.instant('notice.text');
    vm.text = $sce.trustAsHtml(text);
    
    var msg = "El socio "+vm.user.firstName+" "+vm.user.lastName+", Nro. "+vm.user.externalId+" o un integrante de su grupo familiar";
        msg+= " da aviso de que posee COVID-19 positivo. <br><br>";
        msg+= "Sus datos de contacto son: <br><br>";
        msg+= "Tel&eacute;fono: "+vm.user.phone+". <br>";
        msg+= "C&eacute;dula: "+vm.user.identificationNumber+". <br><br>";
        msg+= "Grupo familiar: <br>";

    vm.dependents.map(function(item){
      msg+= "      "+item.firstName+" "+item.lastName+", Nro. "+item.externalId+". <br>";
    });


    vm.send = function(){
      var data = {subject:"Club Malvín - Aviso de COVID-19 positivo",title:"Aviso de COVID-19 Positivo",user:vm.user.id,msg:msg};
      Request.post('email/',true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('notice.msg_sent'));
        }
      });
    };

    // MODAL
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirm.html',
        controller: 'ModalNoticeController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          msg: function () {
            return vm.msg;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
              vm.send();
          }
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.confirm = function(){
      vm.msg = "";
      vm.open();
    };

  });

  angular.module('ufcw').controller('ModalNoticeController', function ($uibModalInstance, $log, msg, $translate) {
    var vm = this;
    
    vm.msg = msg;
    vm.text = $translate.instant('confirm.text');
    
    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'confirm.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.msg = vm.resolve.msg;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });
