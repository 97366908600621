'use strict';

angular.module('ufcw', [
  'toaster',
    'ngAria',
    'ngCookies',
    'ngMessages',
    'ngResource',
    'ui.router',
    'ngSanitize',
    'ui.bootstrap',
    'rzSlider',
    'pascalprecht.translate'
  ]);
