'use strict';

angular.module('ufcw')
  .controller('MessageController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document, $translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.messages");

    vm.loadMessages = function(){
      //get next reservations for user
      
      Request.get('message/',true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }else{
          vm.messages = response.description;
          vm.messages.messages.map(function(item){
            item.created = new Date(item.created);
          });
          $localstorage.setObject('messages',response.description);
          $rootScope.messages = response.description;
          vm.no_messages = (vm.messages.messages.length === 0)?true:false;
        }
      });
    };

    vm.loadMessages();

    // vm.sendNotification = function(id,action){
    //   var data = {"type":"email","action":action,"reservationId":id};
    //   Request.post('notification/',true,data,function() {
    //   });
    // };

    // vm.cancelReservation = function (id) {
    //   Request.delete('reservation/?id='+id,true,function(response) {
    //     if(response.status !== 0){
    //       toaster.pop('error', "", response.description);
    //     }else{
    //       toaster.pop('success', "", "Reserva cancelada correctamente");
    //       vm.load();
    //       vm.sendNotification(id,'cancel');
    //     }
    //   });
    // };

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    // vm.open = function (size, parentSelector) {
    //   var parentElem = parentSelector ? 
    //     angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    //   var modalInstance = $uibModal.open({
    //     animation: vm.animationsEnabled,
    //     ariaLabelledBy: 'modal-title',
    //     ariaDescribedBy: 'modal-body',
    //     templateUrl: 'confirmareserva.html',
    //     controller: 'ModalReservaController',
    //     controllerAs: 'modalCtrl',
    //     size: size,
    //     backdrop: 'static',
    //     keyboard: false,
    //     appendTo: parentElem,
    //     resolve: {
    //       title: function () {
    //         return vm.title;
    //       },
    //       day: function () {
    //         return vm.activity.day;
    //       },
    //       dependents: function () {
    //         return vm.dependents;
    //       },
    //       action: function () {
    //         return vm.action;
    //       },
    //       activity: function () {
    //         return vm.activity;
    //       },
    //       showInvitation: function () {
    //         return vm.showInvitation;
    //       }
    //     }
    //   });
  
    //   modalInstance.result.then(function (result) {
    //       if(result.status === 'OK'){
    //         vm.activity = result.activity;
    //         vm.cancelReservation(vm.activity.id);
    //       }
    //   }, function () {
    //     // $log.info('Modal dismissed at: ' + new Date());
    //   });
    // };



    // vm.cancelRes = function(activity){
    //   vm.activity = activity;
    //   vm.activity.description = "";
    //   vm.at = vm.activity.id;
    //   vm.id = $rootScope.user.id;
    //   vm.moredays = (vm.showNextDay)?0:1;
    //   vm.title = "Cancelar reserva";
    //   vm.showInvitation = false;
    //   if($rootScope.inArray(vm.activity.activityId,activity_invitations)){
    //     vm.showInvitation = true;
    //   }
    //   vm.open();
    // };

    vm.openViewMsg = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'message.html',
        controller: 'ModalMessageController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          msg: function () {
            return vm.msg;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        vm.loadMessages();
      });
    };

    vm.viewMsg = function(msg){
      vm.msg = msg;
      Request.put('message/'+msg.id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }
      });
      vm.openViewMsg();
    };

    vm.deleteMsg = function(msg){
      Request.delete('message/'+msg.id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", response.description);
        }
        vm.loadMessages();
      });
    };

  });

  angular.module('ufcw').controller('ModalMessageController', function ($uibModalInstance,$log,msg) {
    var vm = this;
    
    vm.msg = msg;
  
    vm.ok = function () {
      
    };
  
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  });

  angular.module('ufcw').component('modalComponent', {
    templateUrl: 'message.html',
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var vm = this;
  
      vm.$onInit = function () {
        vm.msg = vm.resolve.msg;
      };
  
      vm.ok = function () {
        vm.close({$value: 'OK'});
      };
  
      vm.cancel = function () {
        vm.dismiss({$value: 'cancel'});
      };
    }
  });