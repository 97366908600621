'use strict';

angular.module('ufcw')
  .controller('HomeController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    vm.reservation_enabled = true;

    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    vm.show_msg = $localstorage.get('showMsg');
    var config  = $localstorage.getObject('config');
    if(config.reservationEnabled===0){
      vm.reservation_enabled = false;
      vm.notice_title = config.noticeAlertTitle;
      vm.notice_text = config.noticeAlertText;
    }

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.activities");


        //para sinergia
        if($rootScope.projectname==="sinergia"){    
          if(angular.isDefined($window.Beacon)){
            $window.Beacon('identify', {
              name: user.firstName+" "+user.lastName,
              email: user.email,
              });
          }
        }
        
        //fin sinergia
          
    //get activities for user category
    Request.get('activitycategory',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
        });
      }
    });
      
  // MODAL
  vm.animationsEnabled = true;
      
  vm.open = function (size, parentSelector) {
    var tam = (size)?size:'lg';
    var parentElem = parentSelector ? 
      angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
    var modalInstance = $uibModal.open({
      animation: vm.animationsEnabled,
      ariaLabelledBy: 'modal-title',
      ariaDescribedBy: 'modal-body',
      templateUrl: 'initialmsg.html',
      controller: 'ModalInitialMsgController',
      controllerAs: 'modalCtrl',
      backdrop: 'static',
      keyboard: false,
      size: tam,
      appendTo: parentElem,
      resolve: {
        msg: function () {
          return vm.msg;
        }
      }
    });

    modalInstance.result.then(function () {
      }, function () {
    });
  };

  vm.showInit = function(){
    var config = $localstorage.getObject('config');
    vm.msg = config.initialMsg;
    vm.open();
  };

  if(vm.show_msg === 'true'){
    vm.showInit();
  }

  
  });

  angular.module('ufcw').controller('ModalInitialMsgController', function ($uibModalInstance,$log,msg,$localstorage,$sce) {
    var vm = this;
    vm.msg = $sce.trustAsHtml(msg);

    vm.ok = function () {
      $uibModalInstance.close({status: 'OK'});
    };

    vm.cancel = function () {
      $localstorage.set('showMsg',false);
      $uibModalInstance.dismiss('cancel');
    };

  });

  angular.module('ufcw').component('modalComponent', {
  templateUrl: 'initialmsg.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: function () {
    var vm = this;

    vm.$onInit = function () {
      vm.msg = vm.resolve.msg;
    };

    vm.ok = function () {
      vm.close({$value: 'OK'});
    };

    vm.cancel = function () {
      vm.dismiss({$value: 'cancel'});
    };
  }
});



