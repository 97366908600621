'use strict';

angular.module('ufcw')
  .controller('RecoveryController', function ($rootScope,$localstorage,Request,$log,$state,toaster,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    vm.errorMsg = null;
    
    vm.recovery = function(){  
      var data = {email:vm.email};
      Request.post('recovery',false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('api.'+response.description));
          $state.go('setpwd');
        }
      });
      
    };

    vm.setpwd = function(){  
      
      var data = {password:vm.password};

      Request.put('recovery/?token='+vm.token,false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant('api.'+response.description));
          $state.go('signin');
        }
      });
      
    };
  });
