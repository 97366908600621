angular.module('ufcw')

.constant('EnvConfig', {
  "URL_API"			: "http://api.agenda/",
  "URL_IMAGES"  : "http://api-admin.agenda/images/",
  "COMPANY_ID"  : "1",
  "ACTIVITY_WITH_INV": "",
  "SIGNIN_ENABLED":true,
  "RESERVATION_ENABLED":true,
  "footer_app_name":"ReservasWeb",
  "footer_email":"hi@squal.us",
  "footer_facebook":"https://www.facebook.com/centroenfoque",
  "footer_twitter":"https://twitter.com/centroenfoque",
  "footer_instagram":"https://www.instagram.com/centroenfoque",
  "footer_youtube":"https://www.youtube.com/channel/UCLYzsh_m2QeWOJ8UuRSUJYQ",
  "show_aviso_covid":true,
  "show_reglas":false,
  "signup_nrosocio":false,
  "signup_dob":false,
  "projectname":"reservasweb"
});
