'use strict';

angular.module('ufcw')
  .controller('AdvancedsearchController', function ($log,Request,$localstorage,$rootScope,$uibModal,$document,toaster,$translate) {
    var vm = this;
    
    // vm.user  = $localstorage.getObject('user');
    // vm.dependents = $localstorage.getObject('dependents');
    vm.edad = {
      value: 25,
      options: {
          floor: 1,
          ceil: 100
      }
  };

  vm.hora = {
    value: 0,
    maxValue: 24,
    options: {
        floor: 0,
        ceil: 24,
    }
};
//estos son los que tienen el valor, cuando hacen click en buscar lo tenes
//vm.edad.value
//vm.hora.value

    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("advsearch.title");
    // vm.advSearch = {};
    vm.listdays = {};
    vm.selActivity = 0;
    vm.emptylist = 0;
    // var msg = "El socio "+vm.user.firstName+" "+vm.user.lastName+", Nro. "+vm.user.externalId+" o un integrante de su grupo familiar";
    //     msg+= " da aviso de que posee COVID-19 positivo. <br><br>";
    //     msg+= "Sus datos de contacto son: <br><br>";
    //     msg+= "Tel&eacute;fono: "+vm.user.phone+". <br>";
    //     msg+= "C&eacute;dula: "+vm.user.identificationNumber+". <br><br>";
    //     msg+= "Grupo familiar: <br>";

    // vm.dependents.map(function(item){
    //   msg+= "      "+item.firstName+" "+item.lastName+", Nro. "+item.externalId+". <br>";
    // });


    // vm.send = function(){
    //   var data = {subject:"Club Malvín - Aviso de COVID-19 positivo",title:"Aviso de COVID-19 Positivo",user:vm.user.id,msg:msg};
    //   Request.post('email/',true,data,function(response) {
    //     if(response.status !== 0){
    //       toaster.pop('error', "", response.description);
    //     }else{
          // toaster.pop('test');
    //     }
    //   });
    // };

    // MODAL
    vm.animationsEnabled = true;

    Request.get('activitycategory',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.listactivities = response.description;
       
        
        // vm.activities.map(function(item){
        //   item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
        // });
      }
    });

    vm.search = function(){
      var axdays = [];
      var axhours = {};
      var axactivity;
      var axage;


    //   console.log('edad',vm.edad.value);
    // console.log('hora',vm.hora);

      // var days =[];
      
      axdays= vm.getDaysSelected();

      if (vm.hora.value.toString().length===1){
        axhours.start = "0"+vm.hora.value+":00";
      }else{
        axhours.start = vm.hora.value+":00";
      }

      if (vm.hora.maxValue.toString().length===1){
        axhours.end = "0"+vm.hora.maxValue+":00";
      }else{
        axhours.end = vm.hora.maxValue+":00";
      }

      if (vm.selActivity){
        axactivity = vm.selActivity;
      }else{
        axactivity= 0;
      }

      if (vm.edad.value>0){
        axage = vm.edad.value;
      }else{
        axage= 0;
      }

      

      var data = {days:axdays,hours:axhours,activity:axactivity,age:axage};
      Request.post('advancedsearch/',true,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          vm.listactivitytimes = response.description.list;
          if (vm.listactivitytimes.length===0){
            vm.emptylist = 1;
          }else{
            vm.emptylist = 0;
          }
          
        }
      });
    };


    vm.getnameday=function(codday){
      var nameday = "";
      switch (codday) {
        case 0:
          nameday = $translate.instant('days.sunday');
          break;
        case 1:
          nameday = $translate.instant('days.monday');
          break;
        case 2:
          nameday = $translate.instant('days.tuesday');
          break;
        case 3:
          nameday = $translate.instant('days.wednesday');
          break;
        case 4:
          nameday = $translate.instant('days.thursday');
          break;
        case 5:
          nameday = $translate.instant('days.friday');
          break;
        case 6:
          nameday = $translate.instant('days.saturday');
          break;
    
        default:
          break;
      }
      return nameday;
    };
  
    vm.loadDaysOfWeek = function(){
      var mList = [
        {
          id: 0,
          value: $translate.instant('days.sunday'),
        },
        {
          id: 1,
          value: $translate.instant('days.monday'),
        },
        {
          id: 2,
          value: $translate.instant('days.tuesday'),
        },{
          id: 3,
          value: $translate.instant('days.wednesday'),
        },
        {
          id: 4,
          value: $translate.instant('days.thursday'),
        },
        {
          id: 5,
          value: $translate.instant('days.friday'),
        },
        {
          id: 6,
          value: $translate.instant('days.saturday'),
        }
      ];
      return mList;
  
    };

    vm.listdays = vm.loadDaysOfWeek();

    vm.getDaysSelected=function(){
      var ressultArr = [];
      if (vm.listdays.monday){
        ressultArr.push(1);
      }
      if (vm.listdays.tuesday){
        ressultArr.push(2);
      }
      if (vm.listdays.wednesday){
        ressultArr.push(3);
      }
      if (vm.listdays.thursday){
        ressultArr.push(4);
      }
      if (vm.listdays.friday){
        ressultArr.push(5);
      }
      if (vm.listdays.saturday){
        ressultArr.push(6);
      }
      if (vm.listdays.sunday){
        ressultArr.push(0);
      }
  
      return ressultArr;
    };
    
   
  });

 