'use strict';

angular.module('ufcw')
  .controller('WaitinglistController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');

    vm.users = $localstorage.getObject('dependents');
    vm.has_dependent = (vm.users.length === 0)?false:true;
    vm.users.push(user);

    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("waitinglist.title");

    var STATUS_MAP = {
      ACTIVE: $translate.instant("reserve.status_active"),
      CANCELED: $translate.instant("reserve.status_canceled"),
      null: '',
      '': '',
      undefined: '',
    };

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      //get next reservations for user
      var params = "";
     //por ahora traigo todas las reservas
      // if(vm.from && vm.to){
      //   params = "?from="+vm.from+"&to="+vm.to;
      // }
      // params = "?from=2000-01-01&to=2040-01-01";
      Request.get('waitinglist/'+params,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          vm.reservas = response.description;
          vm.reservas.map(function(item){
            item.status = STATUS_MAP[item.status];
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            var d = new Date(item.reservationdate);
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    vm.load();


    vm.removeItem = function(item){
      var id = item.id;
      Request.delete('waitinglist/?id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("waitinglist.removed"));
          vm.load();
        }
      });
    };



  //DATETIMEPICKERS

  function getDayClass(data) {
    var date = data.date,
      mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < vm.events.length; i++) {
        var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return vm.events[i].status;
        }
      }
    }

    return '';
  }

  vm.today = function() {
    vm.dt = new Date();
  };
  vm.today();

  vm.clear = function() {
    vm.dt = null;
  };

  vm.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true
  };

  // Disable weekend selection
  function disabled(data) {
    var date = data.date,
      mode = data.mode;
    return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
  }

  vm.dateOptions = {
    dateDisabled: disabled,
    formatYear: 'yy',
    maxDate: new Date(2020, 5, 22),
    minDate: new Date(),
    startingDay: 1
  };

  vm.toggleMin = function() {
    vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
    vm.dateOptions.minDate = vm.inlineOptions.minDate;
  };

  vm.toggleMin();

  vm.open1 = function() {
    vm.popup1.opened = true;
  };

  vm.open2 = function() {
    vm.popup2.opened = true;
  };

  vm.setDate = function(year, month, day) {
    vm.dt = new Date(year, month, day);
  };

  vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  vm.format = vm.formats[0];
  vm.altInputFormats = ['M!/d!/yyyy'];

  vm.popup1 = {
    opened: false
  };

  vm.popup2 = {
    opened: false
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  vm.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

});
