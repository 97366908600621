'use strict';

angular.module('ufcw')

.controller('main', ['$scope','$rootScope','$state','$stateParams','$log','$localstorage','EnvConfig','$translate', function($scope,$rootScope,$state,$stateParams,$log,$localstorage,EnvConfig,$translate) {
  $rootScope.footer_app_name    = EnvConfig.footer_app_name;
  $rootScope.footer_email       = EnvConfig.footer_email;
  $rootScope.footer_facebook    = EnvConfig.footer_facebook;
  $rootScope.footer_twitter     = EnvConfig.footer_twitter;
  $rootScope.footer_instagram   = EnvConfig.footer_instagram;
  $rootScope.footer_youtube     = EnvConfig.footer_youtube;
  $rootScope.show_aviso_covid   = EnvConfig.show_aviso_covid;
  $rootScope.show_reglas        = EnvConfig.show_reglas;
  $rootScope.signup_nrosocio    = EnvConfig.signup_nrosocio;
  $rootScope.signup_dob         = EnvConfig.signup_dob;
  $rootScope.projectname         = EnvConfig.projectname;
  

  $rootScope.isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  
	$rootScope.$on('$stateChangeSuccess', function(evt, toState, toParams, fromState, fromParams) {
    
    if(!$rootScope.inArray(toState.name,['signin','recovery','setpwd','signup','welcome/:token'])){
      
      var token = $localstorage.get('token');
      var user  = $localstorage.getObject('user');
      var messages  = $localstorage.getObject('messages');
      if(user){
        user.nameToShow = user.firstName.split(" ");
        user.nameToShow = user.nameToShow[0];
        $translate.use(user.lang);
      
    
      if(!token || !user){
        $rootScope.user = {};
        $localstorage.clear();
        $state.go('signin');
      }
      $rootScope.user = user;
      $rootScope.messages = messages;
    }
    }
    
  });

  $rootScope.logout = function(){
    $localstorage.clear();
    $rootScope.user = {};
    $state.go('signin');
  };

  $rootScope.inArray = function(value, haystack){
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == value)
      return true;
    }
    return false;
  };

}]);
