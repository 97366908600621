'use strict';

angular.module('ufcw')
  .controller('SigninController', function ($rootScope,$localstorage,Request,$log,$state,toaster,EnvConfig,$window,$translate) {
    
    //sinergia
    if($rootScope.projectname==="sinergia"){    
      if(angular.isDefined($window.Beacon)){
        $window.Beacon('init', '05e435cd-5193-4ab7-b019-76ce769a76c2');  
        $window.Beacon('logout');
      }
    }
    
    //fin sinergia
    
    $rootScope.headLander = false;
    var vm = this;
    vm.errorMsg = null;
    $localstorage.clear();
    
    vm.signin = function(){

      if(EnvConfig.SIGNIN_ENABLED||vm.email==="gonzalo@squal.us"||vm.email==="juanto@squal.us"||vm.email==="licdiegocarballo@gmail.com"||vm.email==="fernandaespinosa93@gmail.com"||vm.email==="ignacioveigatdf@gmail.com"){
        var data = {user:vm.email,password:vm.password};

        Request.put('signin',false,data,function(response) {
          if(response.status !== 0){
            // vm.errorMsg = response.description;
            toaster.pop('error', "", $translate.instant('api.'+response.description));
            $localstorage.clear();
          }else{
            $localstorage.set('token',response.token);
            $localstorage.setObject('user',response.description);
            $localstorage.setObject('dependents',response.dependents);
            $translate.use(response.description.lang);
            Request.get('configuration/'+response.description.companyId,true,function(response) {
              $localstorage.set('showMsg',(response.description.showInitialMsg===1)?true:false);
              $localstorage.setObject('config',response.description);
              Request.get('message/',true,function(response) {
                $localstorage.setObject('messages',response.description);
                $rootScope.messages = response.description;
              });
              $state.go('home');
            });

          }
        });
       
        
      }else{
        toaster.pop('info',"", $translate.instant("signin.soon"));
      }
      

      
      
    };
  });
