'use strict';

angular.module('ufcw')
  .controller('FronthorariosController', function ($log,Request,$rootScope,$location,toaster,$translate) {
    var vm = this;
    vm.errorMsg = null;
    var id = $location.search().id; 
    
    $rootScope.headLander = false;
    
    function unique(value, index, self){
      return self.indexOf(value) === index;
    }

    vm.load = function(){
      //get activities for user category
      Request.get('nooauthactivity/?type=act&id='+id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response));
        }else{
          var categories = [];
          for (var day in response.description) {
            for (var acts in response.description[day]) {
              categories.push(response.description[day][acts].UserCategory);
            }
          }
          vm.activities = response.description;
          vm.ucategories = categories.filter(unique);
          vm.show_select = (vm.ucategories.length>1)?true:false;
        } 
      });
    };
  
    vm.getnameday=function(codday){
      var nameday = "";
      switch (codday) {
        case 0:
          nameday = $translate.instant('days.sunday');
          break;
        case 1:
          nameday = $translate.instant('days.monday');
          break;
        case 2:
          nameday = $translate.instant('days.tuesday');
          break;
        case 3:
          nameday = $translate.instant('days.wednesday');
          break;
        case 4:
          nameday = $translate.instant('days.thursday');
          break;
        case 5:
          nameday = $translate.instant('days.friday');
          break;
        case 6:
          nameday = $translate.instant('days.saturday');
          break;
    
        default:
          break;
      }
      return nameday;
    };

    vm.load();

  });

  



