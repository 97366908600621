'use strict';

angular.module('ufcw')
  .controller('WelcomeController', function ($rootScope,EnvConfig,Request,$log,$state,toaster,$location,$translate) {
    $rootScope.headLander = false;
    var vm = this;
    
    var token = $location.search().token; 
    
    
    vm.activate = function(token){
      var data = {};
      Request.put('signup/?token='+token,false,data,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("welcome.success"));
        }
      });
    };

    if(token){
      vm.activate(token);
    }
    

  });
