'use strict';

angular.module("ufcw")
.directive('loading', ['$http','$rootScope', function ($http) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        scope.isLoading = function () {
          return $http.pendingRequests.length > 0;
        };
        scope.$watch(scope.isLoading, function (value) {
            if (value) {
                element.removeClass('ng-hide'); //muestro

            } else {
              element.addClass('ng-hide'); //oculto
            }
          
        });
      }
    };
}]);

